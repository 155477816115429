import React, { Suspense } from 'react';
import { Route, Switch, Router } from 'react-router-dom';
import { FadeLoader } from 'react-spinners';
import { css } from '@emotion/react';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import history from './history';
import './styles/global.sass';

const Profile = React.lazy(() => import('./views/Profile'));
const Login = React.lazy(() => import('./views/Login'));
const SearchResult = React.lazy(() => import('./views/SearchResult'));
const RegisterPage = React.lazy(() => import('./views/Registration'));
const CheckoutPage = React.lazy(() => import('./views/CheckoutPage'));
const ForgotPassword = React.lazy(() => import('./views/ForgotPassword'));
const ResetPassword = React.lazy(() => import('./views/ResetPassword'));
const ResetPass = React.lazy(() => import('./views/ResetPass'));
const Home = React.lazy(() => import('./views/Home'));
const PreviousOrdersPage = React.lazy(() => import('./views/PreviousOrdersPage'));
const OrderDetail = React.lazy(() => import('./views/OrderDetailPage'));
const TestQr = React.lazy(() => import('./TestQr'));
const CartPage = React.lazy(() => import('./views/CartPage'));
const RestaurantByTable = React.lazy(() => import('./views/RestaurantByTable'));
const ConfirmPage = React.lazy(() => import('./views/ConfirmOrderPage'));
const Page404 = React.lazy(() => import('./views/Page404'));
// const AboutPage = React.lazy(() => import('./views/About'))
const WerkenAlsRunner = React.lazy(() => import('./views/WerkenAlsRunner'));
const Franchise = React.lazy(() => import('./views/Franchise'));
const CourierService = React.lazy(() => import('./views/EndToEnd/CourierService'));
const CourierPaymentConfirmPage = React.lazy(
  () => import('./views/EndToEnd/CourierPaymentConfirmPage')
);
const NewShipping = React.lazy(() => import('./views/EndToEnd/NewShipping'));
const CourierConfirmPage = React.lazy(() => import('./views/EndToEnd/CourierConfirmPage'));
const PaymentsHistory = React.lazy(() => import('./views/EndToEnd/PaymentsHistory'));

function App() {
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: #be0a27;
    margin-top: 20rem;
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: transparent;
  `;
  const fallbackUI = () => (
    <FadeLoader css={override} width={5} height={15} margin="2px" color="#be0a27" />
  );

  React.useEffect(() => {
    const root = document.getElementById('root');

    if (root) {
      const childs = Array.from(root.children);
      // console.clear();
      if (childs.length === 0) {
        window.location.href = '/notfound';
      }
    }
  }, []);

  return (
    <>
      <ErrorBoundary>
        <Router history={history}>
          <Suspense fallback={fallbackUI()}>
            <Switch>
              <Route exact={true} path="/" component={Home} />
              <Route exact={true} path="/login/:backurl?/:backurl2?" component={Login} />
              <Route exact={true} path="/search/:zipCode" component={SearchResult} />
              <Route exact={true} path="/order" component={OrderDetail} />
              <Route exact={true} path="/orders_history" component={PreviousOrdersPage} />
              <Route exact={true} path="/registration" component={RegisterPage} />
              <Route exact={true} path="/cartpage" component={CartPage} />
              <Route exact={true} path="/checkout" component={CheckoutPage} />
              <Route exact={true} path="/confirmpage" component={ConfirmPage} />
              {/* <Route exact={true} path="/about" component={AboutPage} /> */}
              <Route exact={true} path="/werken_als_runner" component={WerkenAlsRunner} />
              <Route exact={true} path="/franchise" component={Franchise} />
              <Route exact={true} path="/end-to-end" component={CourierService} />
              <Route exact={true} path="/end-to-end-new" component={NewShipping} />
              <Route exact={true} path="/end-to-end/confirmpage" component={CourierConfirmPage} />
              <Route exact={true} path="/end-to-end/payments_history" component={PaymentsHistory} />
              <Route
                exact={true}
                path="/end-to-end/courier_credits_confirmpage"
                component={CourierPaymentConfirmPage}
              />

              <Route exact={true} path="/restaurant/:restaurantSlug" component={Profile} />
              <Route exact={true} path="/restaurant/:restaurantSlug/:tableId" component={Profile} />
              <Route
                exact={true}
                path={'/:restaurantId/:tblId(\\d+)'}
                component={RestaurantByTable}
              />

              <Route exact={true} path="/qr" component={TestQr} />
              <Route
                path="/reset_password/:restid/forgotPasswordReset/:code"
                component={ResetPass}
              />
              <Route exact={true} path="/forgot_password" component={ForgotPassword} />
              <Route exact={true} path="/confirm_password" component={ResetPassword} />
              <Route exact={true} path="/notfound" component={Page404} />
            </Switch>
          </Suspense>
        </Router>
      </ErrorBoundary>
    </>
  );
}
export default App;
